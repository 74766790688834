<template>
  <v-container fluid class="pt-0">
    <template v-if="loadingWorksheet">
      <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
        <loading-dialog v-bind:loading-message="loadingDialog.message" />
      </v-dialog>
    </template>
    <template v-else>
      <template v-if="loadingWorksheetIsSuccess">
        <!-- MENÜSOR START -->
        <v-row class="pa-3 primary">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon color="white" class="mr-3" v-on="on" @click="$router.back()">
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('button.back') }}
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon color="white" class="mr-3"
                     @click="toggleInputsEditable"
                     v-on="on"
                     v-if="$route.params.ws !== 'uj-megrendeles' && inputsDisabled">
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('button.edit') }}
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon
                     color="white"
                     class="mr-3"
                     v-on="on"
                     v-if="!inputsDisabled"
                     @click="saveWorksheet">
                <v-icon>
                  mdi-content-save
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('button.save') }}
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon color="white" class="mr-3" v-on="on" v-if="$route.params.ws !== 'uj-megrendeles'" @click="printWorksheetPDF">
                <v-icon>
                  mdi-printer
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('button.print') }}
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon color="white" class="mr-3" @click="callSendWorksheet(worksheet.serviceDetails.id)" v-on="on">
                <template v-if="worksheet.serviceDetails.isEmailSent === 1">
                  <v-icon>
                    mdi-email-check
                  </v-icon>
                </template>
                <template v-else>
                  <v-icon>
                    mdi-email-send
                  </v-icon>
                </template>
              </v-btn>
            </template>
            <span>
              {{ $t('button.sendEmail') }}
            </span>
          </v-tooltip>
          <!--v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon color="white" class="mr-3" v-on="on">
                <v-icon>
                  mdi-microsoft-excel
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('button.export') }}
            </span>
          </v-tooltip-->
        </v-row>
        <!-- MENÜSOR END -->
        <v-row>
          <!-- ÜGYFÉL ADATOK START -->
          <v-col cols="12" md="6">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
                <span class="subtitle-1">{{ $t('stepper.customerDetails') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-6">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="worksheet.customer.lastName"
                                  :label="$t('inputLabels.lastName')"
                                  :rules="inputRules.noSpecialCharAndReq"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  counter="30"
                                  filled
                                  :disabled="inputsDisabled"
                                  required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="worksheet.customer.firstName"
                                  :label="$t('inputLabels.firstName')"
                                  :rules="inputRules.noSpecialCharAndReq"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  counter="30"
                                  filled
                                  :disabled="inputsDisabled"
                                  required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="worksheet.customer.email"
                                  :label="$t('inputLabels.email')"
                                  :rules="inputRules.emailRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  counter="30"
                                  filled
                                  :disabled="inputsDisabled"
                                  required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="worksheet.customer.phoneNumber"
                                  :label="$t('inputLabels.phone')"
                                  :rules="inputRules.phoneRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  prefix="+36-"
                                  v-mask="mask"
                                  filled
                                  :disabled="inputsDisabled"
                                  required>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- ÜGYFÉL ADATOK END -->
          <!-- CÍM ADATOK START -->
          <v-col cols="12" md="6">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-truck-fast-outline</v-icon>
                <span class="subtitle-1">{{ $t('lg.installationAndBillingAddress') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="deliveryInAddressString"
                                  :label="$t('lg.installationAddress')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  append-icon="mdi-pencil"
                                  readonly
                                  :disabled="inputsDisabled"
                                  @click="deliveryInAddressModal = true"
                                  @click:append="deliveryInAddressModal = true">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-checkbox v-model="billingAddressIsEqualdeliveryInAddress"
                                class="mt-0" :disabled="!(deliveryInAddressForm || deliveryInAddressString.length > 2) || inputsDisabled">
                      <template slot="label">
                            <span class="body-2">
                              {{ $t('invoicingAddressIsEqualShippingAddress') }}
                            </span>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-text-field v-model="billingAddressString"
                                  :label="$t('invoicingAddress')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  append-icon="mdi-pencil"
                                  readonly
                                  :disabled="billingAddressIsEqualdeliveryInAddress || inputsDisabled"
                                  @click="billingAddressModal = true"
                                  @click:append="billingAddressModal = true">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- CÍM ADATOK END -->
          <!-- ESZKÖZ ADATOK START -->
          <v-col cols="12" md="6">
            <v-row>
              <!-- ESZKÖZ ADATOK START -->
              <v-col cols="12">
                <v-card>
                  <v-card-title>
                    <v-icon color="primary" class="mr-4">mdi-devices</v-icon>
                    <span class="subtitle-1">{{ $t('lg.deviceDetails') }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pa-6">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-autocomplete v-model="worksheet.serviceDetails.deviceDetails.deviceType"
                                        :items="selectFieldOptions.productTypes"
                                        item-text="deviceTypeName"
                                        item-value="deviceTypeId"
                                        :label="$t('lg.productType')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        :no-data-text="$t('noDataText.general')"
                                        persistent-hint
                                        filled
                                        :disabled="inputsDisabled"
                                        return-object
                                        required>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="worksheet.serviceDetails.deviceDetails.deviceIdentifier"
                          :label="$t('lg.productTypeIdentifier')"
                          :rules="inputRules.generalRules"
                          :hint="$t('inputMessages.requiredField')"
                          persistent-hint
                          filled
                          :disabled="inputsDisabled"
                          required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="worksheet.serviceDetails.deviceDetails.placeOfPurchase"
                          :label="$t('lg.placeOfPurchase')"
                          :rules="inputRules.generalRules"
                          :hint="$t('inputMessages.requiredField')"
                          persistent-hint
                          filled
                          :disabled="inputsDisabled"
                          required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="worksheet.serviceDetails.deviceDetails.dateOfPurchase"
                          :label="$t('dateOfPurchase')"
                          :rules="inputRules.purchaseDateRule"
                          :hint="$t('inputMessages.purchaseDateReq')"
                          persistent-hint
                          :disabled="inputsDisabled"
                          v-mask="dateTextMask"
                          filled>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- ESZKÖZ ADATOK END -->
              <!-- TARTOZÉK ADATOK START -->
              <v-col cols="12">
                <v-card>
                  <v-card-title>
                    <v-icon color="primary" class="mr-4">mdi-tag-text</v-icon>
                    <span class="subtitle-1">{{ $t('lg.accessories') }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pa-6">
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <template v-for="(item, index) in worksheet.serviceDetails.extraAccessories">
                            <v-col cols="12" sm="6" :key="`${index}n`">
                              <v-row class="py-3 mx-0">
                                <div class="mr-3 text-h6">
                                  {{ index + 1 }}.
                                </div>
                                <v-text-field
                                  v-model="item.name"
                                  :label="$t('lg.extraAccessoryName')"
                                  :hint="$t('inputMessages.optionalField')"
                                  persistent-hint
                                  filled
                                  :disabled="inputsDisabled"
                                  required>
                                </v-text-field>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="6" :key="`${index}a`">
                              <v-row class="py-3 mx-0">
                                <v-text-field
                                  v-model="item.amount"
                                  :label="$t('lg.extraAccessoryAmount')"
                                  :hint="$t('inputMessages.optionalFieldOnlyNumbers')"
                                  persistent-hint
                                  v-mask="maskAmount"
                                  clearable
                                  filled
                                  :disabled="inputsDisabled"
                                  required>
                                </v-text-field>
                                <v-btn v-if="index > 0"
                                       icon
                                       color="error"
                                       class="mt-3"
                                       :disabled="inputsDisabled"
                                       @click="deleteAccessory(index)">
                                  <v-icon>
                                    mdi-minus-circle
                                  </v-icon>
                                </v-btn>
                              </v-row>
                            </v-col>
                            <v-col cols="12" class="py-0" v-if="index !== worksheet.serviceDetails.extraAccessories.length - 1" :key="`${index}d`">
                              <v-row>
                                <v-divider></v-divider>
                              </v-row>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row align="center" class="mx-0">
                          <v-divider></v-divider>
                          <v-btn color="primary"
                                 small
                                 :disabled="inputsDisabled"
                                 @click="addAccessory" class="ml-3">
                            {{ $t('button.addNew') }}
                          </v-btn>
                          <!--v-btn icon color="primary" class="ml-3" @click="addAccessory">
                            <v-icon>
                              mdi-plus-circle
                            </v-icon>
                          </v-btn-->
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="worksheet.serviceDetails.extraAccessoriesNetValue"
                          :label="$t('lg.extraAccessoriesNetValue')"
                          :hint="$t('inputMessages.optionalField')"
                          persistent-hint
                          filled
                          suffix="Ft"
                          v-mask="netValueMask"
                          :disabled="inputsDisabled"
                          required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="worksheet.serviceDetails.extraAccessoriesInvoiceId"
                          :label="$t('lg.extraAccessoriesInvoiceId')"
                          :hint="$t('inputMessages.optionalField')"
                          persistent-hint
                          filled
                          :disabled="inputsDisabled"
                          required>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- TARTOZÉK ADATOK END -->
            </v-row>
          </v-col>
          <!-- ESZKÖZ ADATOK END -->
          <!-- SZOLGÁLTATÁS ADATOK START -->
          <v-col cols="12" md="6">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-account-hard-hat</v-icon>
                <span class="subtitle-1">{{ $t('lg.serviceDetails') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-6">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="worksheet.serviceDetails.dateOfOrder"
                      :label="$t('lg.dateOfOrder')"
                      :rules="inputRules.purchaseDateRule"
                      :hint="$t('inputMessages.purchaseDateReq')"
                      persistent-hint
                      :disabled="inputsDisabled"
                      v-mask="dateTextMask"
                      filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="worksheet.serviceDetails.dateOfInstallation"
                      :label="$t('lg.dateOfInstallation')"
                      :rules="inputRules.purchaseDateRule"
                      :hint="$t('inputMessages.purchaseDateReq')"
                      persistent-hint
                      :disabled="inputsDisabled"
                      v-mask="dateTextMask"
                      filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete v-model="worksheet.serviceDetails.lgService"
                                    :items="selectFieldOptions.services"
                                    item-text="lgServiceName"
                                    item-value="lgServiceId"
                                    :label="$t('lg.service')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('noDataText.general')"
                                    persistent-hint
                                    filled
                                    :disabled="inputsDisabled"
                                    return-object
                                    required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete v-model="worksheet.serviceDetails.lgExtraService"
                                    :items="selectFieldOptions.extraServices"
                                    item-text="lgExtraServiceName"
                                    item-value="lgExtraServiceId"
                                    :label="$t('lg.extraService')"
                                    :hint="$t('inputMessages.optionalField')"
                                    :no-data-text="$t('noDataText.general')"
                                    persistent-hint
                                    clearable
                                    filled
                                    :disabled="inputsDisabled"
                                    return-object
                                    required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete v-model="worksheet.serviceDetails.installationCompany"
                                    :items="selectFieldOptions.installationCompany"
                                    item-text="installationCompanyName"
                                    item-value="installationCompanyId"
                                    :label="$t('lg.installationCompany')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('noDataText.general')"
                                    persistent-hint
                                    filled
                                    :disabled="inputsDisabled"
                                    return-object
                                    required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="worksheet.serviceDetails.other"
                                :label="$t('infoToDeliver')"
                                :rules="inputRules.noSpecialChar"
                                :hint="$t('inputMessages.optionalField')"
                                rows="2"
                                persistent-hint
                                clearable
                                filled
                                :disabled="inputsDisabled"
                                clear-icon="mdi-close"
                                maxlength="1500"
                                counter="1500">
                    </v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input v-model="files"
                                  :label="$t('inputLabels.attachedDocs')"
                                  :rules="inputRules.maxFileSizeOptional"
                                  :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                  persistent-hint
                                  multiple
                                  accept="image/*, .pdf, .doc, .docx"
                                  counter
                                  show-size
                                  :disabled="inputsDisabled"
                                  filled
                                  ref="fileInput">
                      <template v-slot:selection="{text, file}">
                        <v-chip small
                                label
                                close
                                @click:close="removeFileFromInput(file)"
                                text-color="white"
                                :color="file.size > 5000000 ? 'red' : 'primary'">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- SZOLGÁLTATÁS ADATOK END -->
          <!-- FELTÖLTÖTT DOKUMENTUMOK START -->
          <v-col cols="12">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-file-document</v-icon>
                <span class="subtitle-1">{{ $t('lg.uploadedDocuments') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-6">
                <v-row>
                  <template v-for="(document, index) in uploadedDocuments">
                    <v-col col="12" sm="4" :key="index" v-if="document.hash && document.table">
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-list two-line>
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-avatar color="primary">
                                  <v-icon color="white">
                                    mdi-pdf-box
                                  </v-icon>
                                </v-avatar>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title class="">
                                  {{ document.description }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ document.createdAt }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                        <v-card-actions class="px-3">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="downloadFile(document)">
                            {{ $t('button.download') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- FELTÖLTÖTT DOKUMENTUMOK END -->
        </v-row>
        <!-- MENÜSOR START -->
        <v-row class="pa-3" justify="end">
          <v-btn color="primary"
                 class="mr-3"
                 @click="toggleInputsEditable"
                 v-if="$route.params.ws !== 'uj-megrendeles' && inputsDisabled">
            {{ $t('button.edit') }}
          </v-btn>
          <v-btn color="primary"
                 class="mr-3"
                 v-if="!inputsDisabled"
                 @click="saveWorksheet">
            {{ $t('button.save') }}
          </v-btn>
          <v-btn color="primary"
                 class="mr-3"
                 v-if="$route.params.ws !== 'uj-megrendeles'"
                 @click="printWorksheetPDF">
            {{ $t('button.print') }}
          </v-btn>
          <!--v-btn color="primary" class="mr-3">
            {{ $t('button.export') }}
          </v-btn-->
        </v-row>
        <!-- MENÜSOR END -->
        <!-- SZÁLLÍTÁSI CÍM MODAL START -->
        <v-dialog v-model="deliveryInAddressModal" max-width="800" persistent>
          <v-card tile>
            <v-card-title class="third">
              <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
              <span class="white--text">{{ $t('lg.installationAddress') }}</span>
              <v-spacer></v-spacer>
              <v-icon @click="deliveryInAddressModal = false" color="white">
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-form v-model="deliveryInAddressForm">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-autocomplete v-model="worksheet.installationAddress.postalCode"
                                    :items="selectFieldOptions.postalCodes"
                                    item-text="address.postalCode"
                                    item-value="address.postalCode"
                                    :search-input.sync="postalCodeSearch"
                                    :label="$t('inputLabels.postalCode')"
                                    :rules="inputRules.phoneRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    persistent-hint
                                    filled
                                    maxlength="4"
                                    required
                                    @change="onPostalCodeSelect"
                                    @click:clear="clearAllAddressInputs"
                                    clearable>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete v-model="worksheet.installationAddress.city"
                                    :items="selectFieldOptions.cities"
                                    item-value="address.city"
                                    item-text="address.city"
                                    :search-input.sync="citySearch"
                                    :label="$t('inputLabels.city')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    persistent-hint
                                    filled
                                    required
                                    clearable
                                    @click:clear="clearAllAddressInputs">
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-combobox v-model="streetIdeiglenes"
                                :items="selectFieldOptions.streets"
                                item-value="address.street"
                                item-text="address.street"
                                :search-input.sync="streetSearch"
                                :label="$t('inputLabels.publicPlaceName')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                required
                                @change="onPublicPlaceSelect"
                                clearable>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="worksheet.installationAddress.streetNumber"
                                  :label="$t('inputLabels.streetNumber')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  :no-data-text="$t('inputMessages.noDataText')"
                                  counter="30"
                                  max-length="30"
                                  persistent-hint
                                  filled
                                  required
                                  clearable>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="worksheet.installationAddress.other"
                                :label="$t('infoToDeliver')"
                                :rules="inputRules.noSpecialChar"
                                :hint="$t('inputMessages.optionalField')"
                                persistent-hint
                                clearable
                                filled
                                clear-icon="mdi-close"
                                maxlength="50"
                                counter="50">
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="px-6 pb-6">
              <v-spacer></v-spacer>
              <v-btn text @click="deliveryInAddressModal = false">
                {{ $t('button.cancel') }}
              </v-btn>
              <v-btn class="primary" @click="saveDeliveryInAddressData" :disabled="!deliveryInAddressForm">
                {{ $t('button.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- SZÁLLÍTÁSI CÍM MODAL END -->
        <!-- SZÁMLÁZÁSI CÍM MODAL -->
        <v-dialog v-model="billingAddressModal" max-width="800" persistent>
          <v-card tile>
            <v-card-title class="third">
              <v-icon color="white" class="mr-4">mdi-file-account-outline</v-icon>
              <span class="white--text">{{ $t('invoicingAddress') }}</span>
              <v-spacer></v-spacer>
              <v-icon @click="billingAddressModal = false" color="white">
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-form v-model="billingAddressForm">
                <v-row>
                  <v-col cols="12" class="pt-0">
                    <v-radio-group v-model="worksheet.billingAddress.isCompany"
                                   row mandatory class="mt-0" hide-details>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <div :class="worksheet.billingAddress.isCompany === 0 ? 'bra' : 'bri'"
                               class="pa-3">
                            <v-radio :value="0">
                              <template slot="label">
                                <span class="text-uppercase br-label">{{ $t('inputLabels.privatePerson') }}</span>
                              </template>
                            </v-radio>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div :class="worksheet.billingAddress.isCompany === 1 ? 'bra' : 'bri'"
                               class="pa-3">
                            <v-radio :value="1">
                              <template slot="label">
                                <span class="text-uppercase br-label">{{ $t('inputLabels.company') }}</span>
                              </template>
                            </v-radio>
                          </div>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="worksheet.billingAddress.billingName"
                                  :label="$t('invoicingName')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="worksheet.billingAddress.taxNumber"
                                  :label="$t('vatNumber')"
                                  :rules="worksheet.billingAddress.isCompany === 1 ? inputRules.vatRules : []"
                                  :hint="worksheet.billingAddress.isCompany === 1 ? $t('inputMessages.requiredField') : $t('inputMessages.optionalField')"
                                  v-mask="taxNumberMaskHU"
                                  persistent-hint
                                  filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete v-model="worksheet.billingAddress.postalCode"
                                    :items="selectFieldOptions.postalCodesInvoice"
                                    item-text="address.postalCode"
                                    item-value="address.postalCode"
                                    :search-input.sync="postalCodeSearchInvoice"
                                    :label="$t('inputLabels.postalCode')"
                                    :rules="inputRules.phoneRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    persistent-hint
                                    filled
                                    maxlength="4"
                                    required
                                    @change="onPostalCodeSelectInvoice"
                                    @click:clear="clearAllAddressInputsInvoice"
                                    clearable>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete v-model="worksheet.billingAddress.city"
                                    :items="selectFieldOptions.citiesInvoice"
                                    item-value="address.city"
                                    item-text="address.city"
                                    :search-input.sync="citySearchInvoice"
                                    :label="$t('inputLabels.city')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    persistent-hint
                                    filled
                                    required
                                    clearable
                                    @click:clear="clearAllAddressInputsInvoice">
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-combobox v-model="streetIdeiglenesInvoice"
                                :items="selectFieldOptions.streetsInvoice"
                                item-value="address.street"
                                item-text="address.street"
                                :search-input.sync="streetSearchInvoice"
                                :label="$t('inputLabels.publicPlaceName')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                required
                                @change="onPublicPlaceSelectInvoice"
                                clearable>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="worksheet.billingAddress.streetNumber"
                                  :label="$t('inputLabels.streetNumber')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  :no-data-text="$t('inputMessages.noDataText')"
                                  counter="30"
                                  max-length="30"
                                  persistent-hint
                                  filled
                                  required
                                  clearable>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="worksheet.billingAddress.other"
                                :label="$t('infoToDeliver')"
                                :rules="inputRules.noSpecialChar"
                                :hint="$t('inputMessages.optionalField')"
                                persistent-hint
                                clearable
                                filled
                                clear-icon="mdi-close"
                                maxlength="50"
                                counter="50">
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="px-6 pb-6">
              <v-spacer></v-spacer>
              <v-btn text @click="billingAddressModal = false">
                {{ $t('button.cancel') }}
              </v-btn>
              <v-btn class="primary" @click="saveBillingAddressData" :disabled="!billingAddressForm">
                {{ $t('button.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- RESPONSE DIALOGS START -->
        <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
          <loading-dialog v-bind:loading-message="loadingDialog.message" />
        </v-dialog>
        <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
          <response-dialog v-bind:response-object="responseDialog.response"
                           v-on:close-dialog="closeResponseDialog"/>
        </v-dialog>
      </template>
      <template v-else>
        <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
          <response-dialog v-bind:response-object="responseDialog.response"
                           v-on:close-dialog="closeResponseDialog"/>
        </v-dialog>
      </template>
    </template>
  </v-container>
</template>

<script>
import ResponseDialog from 'src/components/dialogs/ResponseDialog';
import LoadingDialog from 'src/components/dialogs/LoadingDialog';
import dayjs from "dayjs";
import {
  HEREGETPOSTALCODESBYCOUNTRY,
  HEREGETCITYBYPOSTALCODE,
  HEREGETSTREETBYPOSTALCODE
} from 'src/utils/hereAPI';
import { getURA } from 'src/utils/jwtHelper';

export default {
  name: 'LGFormPage',
  components: {
    ResponseDialog, LoadingDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    // console.log(this.$route.params.ws);
    // if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE') {
    if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE' && await getURA().split(',').includes('LG')) {
      if (this.$route.params.ws !== 'uj-megrendeles') {
        this.loadingDialog.message = 'loadingMessages.INPROGRESS_FORMLOADING';
        this.loadingDialog.visible = true;
        this.wsid = this.$route.params.ws;
        this.inputsDisabled = true;
        this.worksheet = await this.getWorksheetDetails(this.wsid);
        this.uploadedDocuments = await this.getWorksheetDocuments(this.wsid);
        this.saveDeliveryInAddressData();
        this.saveBillingAddressData();
      } else {
        this.loadingDialog.visible = false;
        this.loadingWorksheetIsSuccess = true;
      }
      this.loadingWorksheet = false;
      await this.setDates();
      await this.setServiceRequestFormCountry();
    } else {
      await this.$router.push({name: 'iranyitopult'});
    }
  },
  data() {
    return {
      wsid: 'uj-megrendeles',
      loadingWorksheet: true,
      loadingWorksheetIsSuccess: false,
      inputsDisabled: false,
      worksheet: {
        serviceDetails: {
          id: null,
          deviceDetails: {
            deviceType: null,
            deviceIdentifier: '',
            placeOfPurchase: '',
            dateOfPurchase: null,
          },
          dateOfOrder: null,
          dateOfInstallation: null,
          lgService: null,
          lgExtraService: null,
          installationCompany: null,
          extraAccessories: [
            {
              name: '',
              amount: null,
            },
          ],
          extraAccessoriesNetValue: null,
          extraAccessoriesInvoiceId: '',
          other: '',
        },
        customer: {
          id: null,
          customerType: 'LGB2C',
          crmNamePrefixId: null,
          firstName: '',
          middleName: null,
          lastName: '',
          email: '',
          phoneNumber: '',
        },
        installationAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        billingAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
      },
      files: [],
      uploadedDocuments: [],
      netValueMask: '#########',
      mask: '##-###-####',
      maskAmount: '#########',
      dateTextMask: '####-##-##',
      taxNumberMaskHU: '########-#-##',
      maxDate: null,
      /**
       * SZÁLLÍTÁSI CÍMHEZ
       */
      deliveryInAddressString: '',
      deliveryInAddressForm: false,
      deliveryInAddressModal: false,
      countrySearch: '',
      countrySearchIsLoading: false,
      postalCodeSearch: '',
      postalCodeSearchIsLoading: false,
      citySearch: '',
      citySearchIsLoading: false,
      streetSearch: '',
      streetSearchIsLoading: false,
      streetIdeiglenes: '',
      deliveryInAddressBackup: null,
      /**
       * SZÁMLÁZÁSI CÍMHEZ
       */
      billingAddressString: '',
      billingAddressForm: false,
      billingAddressModal: false,
      postalCodeSearchInvoice: '',
      postalCodeSearchIsLoadingInvoice: false,
      citySearchInvoice: '',
      citySearchIsLoadingInvoice: false,
      streetSearchInvoice: '',
      streetSearchIsLoadingInvoice: false,
      streetIdeiglenesInvoice: '',
      billingAddressBackup: null,
      // számlázási cím megegyezik a szállítási címmel
      billingAddressIsEqualdeliveryInAddress: false,
      loadingDialog: {
        visible: false,
        message: 'loadingMessages.INPROGRESS_CONTACT_FORM',
      },
      responseDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'successMessages.SUCCESS_REPAIR_FORM',
        },
      },
      selectFieldOptions: {
        services: [
          { lgServiceId: 1, lgServiceName: 'Kiszállítás' },
          { lgServiceId: 2, lgServiceName: 'TV alap telepítés - 65\'-nál nagyobb' },
          { lgServiceId: 3, lgServiceName: 'TV alap telepítés - 65\'-ig' },
          { lgServiceId: 4, lgServiceName: 'TV alap telepítés - 55\'-ig' },
          { lgServiceId: 5, lgServiceName: 'TV falra telepítés - 65\'-nál nagyobb' },
          { lgServiceId: 6, lgServiceName: 'TV falra telepítés - 65\'-ig' },
          { lgServiceId: 7, lgServiceName: 'TV falra telepítés - 55\'-ig' },
          { lgServiceId: 8, lgServiceName: 'Mosó- és szárítógép telepítés' },
          { lgServiceId: 9, lgServiceName: 'Hűtőszekrény/Styler telepítés' },
          { lgServiceId: 10, lgServiceName: 'Méltányossági szolgáltatás' },
          { lgServiceId: 11, lgServiceName: 'Sidebyside' },
        ],
        extraServices: [
          { lgExtraServiceId: 1, lgExtraServiceName: 'Termék visszaszállítás' },
          { lgExtraServiceId: 2, lgExtraServiceName: 'Tartozék/Kiegészítő értékesítés' },
        ],
        installationCompany: [
          { installationCompanyId: 1, installationCompanyName: 'TMX' },
          { installationCompanyId: 2, installationCompanyName: 'Egyéb' },
        ],
        productTypes: [
          { deviceTypeId: 1, deviceTypeName: 'Televízió' },
          { deviceTypeId: 2, deviceTypeName: 'Hűtőgép' },
          { deviceTypeId: 3, deviceTypeName: 'Styler' },
          { deviceTypeId: 4, deviceTypeName: 'Mosógép' },
          { deviceTypeId: 5, deviceTypeName: 'Szárítógép' },
          { deviceTypeId: 6, deviceTypeName: 'Mosó-szárítógép' },
          { deviceTypeId: 7, deviceTypeName: 'Mosogatógép' },
          { deviceTypeId: 8, deviceTypeName: 'OLED55GX' },
          { deviceTypeId: 9, deviceTypeName: 'OLED65GX' },
          { deviceTypeId: 10, deviceTypeName: 'OLED77GX' },
          { deviceTypeId: 11, deviceTypeName: 'OLED55G1' },
          { deviceTypeId: 12, deviceTypeName: 'OLED65G1' },
          { deviceTypeId: 13, deviceTypeName: 'OLED77G1' },
          { deviceTypeId: 14, deviceTypeName: 'OLED77A1' },
          { deviceTypeId: 15, deviceTypeName: 'OLED77B1' },
          { deviceTypeId: 16, deviceTypeName: 'OLED77C1' },
          { deviceTypeId: 17, deviceTypeName: 'OLED77Z1' },
          { deviceTypeId: 18, deviceTypeName: 'OLED83C1' },
          { deviceTypeId: 19, deviceTypeName: 'OLED55G23' },
          { deviceTypeId: 20, deviceTypeName: 'OLED65G23' },
          { deviceTypeId: 21, deviceTypeName: 'OLED77G23' },
          { deviceTypeId: 22, deviceTypeName: 'OLED83G23' },
          { deviceTypeId: 23, deviceTypeName: 'OLED77B23' },
          { deviceTypeId: 24, deviceTypeName: 'OLED77C21' },
          { deviceTypeId: 25, deviceTypeName: 'OLED77A2' },
          { deviceTypeId: 26, deviceTypeName: 'OLED77G23' },
          { deviceTypeId: 27, deviceTypeName: 'OLED77Z2' },
          { deviceTypeId: 28, deviceTypeName: 'OLED83C21' },
          { deviceTypeId: 29, deviceTypeName: 'W szériás OLED' },
          { deviceTypeId: 30, deviceTypeName: '86QNED99' },
          { deviceTypeId: 31, deviceTypeName: '75QNED99' },
          { deviceTypeId: 32, deviceTypeName: '86QNED91' },
          { deviceTypeId: 33, deviceTypeName: '75QNED91' },
          { deviceTypeId: 34, deviceTypeName: '75QNED96' },
          { deviceTypeId: 35, deviceTypeName: '65QNED96' },
          { deviceTypeId: 36, deviceTypeName: '75QNED86' },
          { deviceTypeId: 37, deviceTypeName: '75QNED87' },
          { deviceTypeId: 38, deviceTypeName: '86QNED86' },
          { deviceTypeId: 39, deviceTypeName: '75QNED82' },
          { deviceTypeId: 40, deviceTypeName: '75QNED81' },
          { deviceTypeId: 41, deviceTypeName: '86QNED81' },
        ],
        countries: [
          { label: 'Magyarország', countryCode: 'HUN' },
        ],
        countriesEN: [
          { label: 'Hungary', countryCode: 'HUN' },
        ],
        countriesDE: [
          { label: 'Ungarn', countryCode: 'HUN' },
        ],
        postalCodes: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        cities: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streets: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        customerShippingAddresses: [],
        customerBillingAddresses: [],
        // számlázási címhez selectek
        postalCodesInvoice: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        citiesInvoice: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streetsInvoice: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
      },
      inputRules: {
        acceptInformation: [v => !!v || this.$t('inputMessages.acceptInformation')],
        generalRules: [v => !!v || this.$t('inputMessages.requiredField')],
        emailRules: [
          v => /.+@.+\..+/.test(v) || this.$t('inputMessages.emailMatch'),
          v => !(/\s/.test(v)) || this.$t('inputMessages.emailNoSpace'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        phoneRules: [
          v => /^[0-9\-]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        onlyNumbers: [
          v => !!v || this.$t('inputMessages.optionalField'),
          v => /^[0-9]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
        ],
        gdprRules: [
          v => !!v || this.$t('inputMessages.requiredPrivacy'),
        ],
        termAndCondRules: [
          v => !!v || this.$t('inputMessages.requiredTermsAndCond'),
        ],
        maxFileSize: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
        ],
        maxFileSizeOptional: [
          v => this.checkMaxFileSizeOK(v) || this.$t('inputMessages.maxFileSizeRule'),
          // v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
        ],
        requiredFile: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        min8: [
          v => v.length >= 8 || this.$t('inputMessages.passwordRuleMin8'),
          v => !!v || this.$t('inputMessages.requiredField')
        ],
        imeiRule: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => /^[0-9]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => (v && v.length === 15) || this.$t('inputMessages.minLenght15'),
        ],
        snRule: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => /^[a-zA-Z0-9\-\/\\]*$/.test(v) || this.$t('inputMessages.snRules'),
          v => (v && v.length < 23) || this.$t('inputMessages.minLenght15'),
        ],
        selectRules: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => ( v && v.id > 0) || this.$t('inputMessages.requiredField'),
        ],
        purchaseDateRule: [
          v => !!v || this.$t('inputMessages.purchaseDateReq'),
          v => this.purchaseDateIsOK(v) || `${this.$t('inputMessages.purchaseDateReq')}`,
          // v => this.purchaseDateIsOK(v) || `${this.$t('inputMessages.purchaseDateReq')} ${this.maxDate}!`,
        ],
        vatRules: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => (v && v.length === 13) || this.$t('inputMessages.requiredField'),
        ],
        noSpecialCharAndReq: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.requiredFieldNoSpecialChar'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
    };
  },
  methods: {
    checkMaxFileSizeOK(files) {
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].size > 5000000) {
            return false;
          }
        }
      }
      return true;
    },
    removeFileFromInput(file) {
      this.files.splice(this.files.indexOf(file), 1);
      this.files = [...this.files];
    },
    async getWorksheetDetails(ws) {
      const response = await this.$store.dispatch('GetLGWorksheetById', ws);
      if (response.status === 200) {
        this.loadingWorksheetIsSuccess = true;
        this.loadingDialog.visible = false;
        this.loadingDialog.message = 'loadingMessages.INPROGRESS_CONTACT_FORM';
        return response.data;
      }
      this.responseDialog.response.message = 'ERROR_MESSAGES.LGWORKSHEET_LOADINGDATAS';
      this.responseDialog.response.error = true;
      this.loadingDialog.visible = false;
      this.responseDialog.visible = true;
      this.loadingWorksheetIsSuccess = false;
      return [];
    },
    async setDates() {
      const now = new Date(1900, 1, 1);
      // const date = dayjs(now).subtract(3, 'day');
      // this.maxDate = now.toISOString().substr(0, 10);
      this.maxDate = now.toISOString().substr(0, 10);
      if (this.$route.params.ws === 'uj-megrendeles') {
        // this.worksheet.serviceDetails.dateOfOrder = now.toISOString().substr(0, 10);
      }
    },
    async callSendWorksheet(wsid) {
      this.loadingDialog.message = 'loadingMessages.INPROGRESS_SENDLGWORKSHEET';
      this.loadingDialog.visible = true;
      const response = await this.$store.dispatch('SendLGWorksheetViaEmail', wsid);
      if (response.status === 200) {
        this.worksheet.serviceDetails.isEmailSent = 1;
        this.loadingDialog.visible = false;
        this.responseDialog.response.error = false;
        this.responseDialog.response.message = 'SUCCESS_MESSAGES.LGWORKSHEET_SEND';
      } else {
        this.responseDialog.response.error = true;
        this.responseDialog.response.message = 'ERROR_MESSAGES.LGWORKSHEET_SEND';
      }
      this.controlDialogs();
    },
    async setServiceRequestFormCountry() {
      if (this.$i18n.locale === 'hu-HU') {
        this.worksheet.installationAddress.country = this.selectFieldOptions.countries[0];
        this.worksheet.billingAddress.country = this.selectFieldOptions.countries[0];
      } else if (this.$i18n.locale === 'de-DE') {
        this.worksheet.installationAddress.country = this.selectFieldOptions.countriesDE[0];
        this.worksheet.billingAddress.country = this.selectFieldOptions.countriesDE[0];
      } else {
        this.worksheet.installationAddress.country = this.selectFieldOptions.countriesEN[0];
        this.worksheet.billingAddress.country = this.selectFieldOptions.countriesEN[0];
      }
    },
    toggleInputsEditable() {
      this.inputsDisabled = false;
    },
    async printWorksheetPDF() {
      const response = this.$store.dispatch('GetLGWorksheetPDF', this.worksheet.serviceDetails.id);
    },
    async saveWorksheet() {
      this.openLoadingDialog();
      if (this.worksheet.serviceDetails.extraAccessories.length === 1) {
        if (this.worksheet.serviceDetails.extraAccessories[0].amount === null || this.worksheet.serviceDetails.extraAccessories[0].amount === '') {
          this.worksheet.serviceDetails.extraAccessories = [];
        }
      }
      if (!this.worksheet.serviceDetails.extraAccessoriesNetValue || this.worksheet.serviceDetails.extraAccessoriesNetValue.length === 0 ) {
        this.worksheet.serviceDetails.extraAccessoriesNetValue = null;
      }
      // Purchase Price ellenőrzése
      if (this.worksheet.serviceRequest.purchasePrice === '') {
        this.worksheet.serviceRequest.purchasePrice = null;
      }
      if (this.worksheet.serviceRequest.purchasePrice) {
        if (this.worksheet.serviceRequest.purchasePrice.length < 1) {
          this.worksheet.serviceRequest.purchasePrice = null;
        }
      }
      let fileUploadResponse;
      if (this.$route.params.ws === 'uj-megrendeles') {
        const response = await this.$store.dispatch('CreateNewLGWorksheet', this.worksheet);
        if (response.status === 201 && (this.files && this.files.length > 0)) {
          fileUploadResponse = await this.$store.dispatch('UploadFilesToLGWorksheet', { id: response.data.lgWorksheetId, files: this.files });
        }
        if (response.status === 201) {
          this.responseDialog.response.error = false;
          this.responseDialog.response.message = 'SUCCESS_MESSAGES.LGWORKSHEET_SAVE';
        } else {
          this.responseDialog.response.error = true;
          this.responseDialog.response.message = 'ERROR_MESSAGES.LGWORKSHEET_SAVE';
        }
      } else {
        const response = await this.$store.dispatch('UpdateLGWorksheet', { id: this.worksheet.serviceDetails.id, worksheet: this.worksheet });
        if (response.status === 200 && (this.files && this.files.length > 0)) {
          fileUploadResponse = await this.$store.dispatch('UploadFilesToLGWorksheet', { id: this.worksheet.serviceDetails.id, files: this.files });
        }
        if (response.status === 200) {
          this.responseDialog.response.error = false;
          this.responseDialog.response.message = 'SUCCESS_MESSAGES.LGWORKSHEET_UPDATE';
        } else {
          this.responseDialog.response.error = true;
          this.responseDialog.response.message = 'ERROR_MESSAGES.LGWORKSHEET_UPDATE';
        }
      }
      this.controlDialogs();
    },
    async getWorksheetDocuments(wsid) {
      const response = await this.$store.dispatch('GetLGWorksheetByIdDocuments', wsid);
      // console.log(response);
      if (response.status === 200) {
        return response.data;
      }
      return [];
    },
    openLoadingDialog() {
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    setupResponseDialog(message) {
      this.responseDialog.response.message = message;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      this.responseDialog.visible = false;
      if (!this.responseDialog.response.error) {
        this.$router.push({ name: 'lg-munkalap' });
      }
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3000);
      setTimeout(this.openResponseDialog, 3010);
    },
    /**
     * ÉRTÉKESÍTETT TARTOZÉKOK
    */
    addAccessory() {
      this.worksheet.serviceDetails.extraAccessories.push({ name: '', amount: null, });
      window.scrollTo(0, document.body.scrollHeight);
    },
    deleteAccessory(index) {
      // const ind = this.worksheet.serviceDetails.extraAccessories.findIndex(item);
      this.worksheet.serviceDetails.extraAccessories.splice(index, 1);
    },
    /**
     * SZÁLLÍTÁSI CÍM MEGADÁSÁHOZ FGV-EK
     */
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.worksheet.installationAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodes = response.data;
        this.postalCodeSearchIsLoading = false;
        this.selectFieldOptions.postalCodes.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      if (this.worksheet.installationAddress.postalCode && this.worksheet.installationAddress.postalCode.address) {
        postalcode = this.worksheet.installationAddress.postalCode.address.postalCode;
        this.worksheet.installationAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.worksheet.installationAddress.postalCode, this.worksheet.installationAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.cities = response.data;
        this.citySearchIsLoading = false;
        this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.worksheet.installationAddress.postalCode, this.worksheet.installationAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streets = response.data;
        this.streetSearchIsLoading = false;
        this.selectFieldOptions.streets.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelect() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
        this.worksheet.installationAddress.city = this.selectFieldOptions.cities[1].address.city;
        this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
      this.worksheet.installationAddress.publicPlaceName = '';
      this.worksheet.installationAddress.publicPlaceType = '';
      this.worksheet.installationAddress.streetNumber = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
    },
    onPublicPlaceSelect() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenes && this.streetIdeiglenes.length > 0) {
        // console.log('1');
        street = this.streetIdeiglenes.substring(0, this.streetIdeiglenes.lastIndexOf(' '));
        type = this.streetIdeiglenes.substring(this.streetIdeiglenes.lastIndexOf(' '), this.streetIdeiglenes.length + 1).trim();
        if (this.streetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          // console.log('1b');
          this.worksheet.installationAddress.publicPlaceName = this.streetIdeiglenes;
          type = this.selectFieldOptions.streets[1].address.street.substring(this.selectFieldOptions.streets[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streets[1].address.street.length + 1).trim();
          this.worksheet.installationAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenes && this.streetIdeiglenes.address) {
        // console.log('2');
        street = this.streetIdeiglenes.address.street.substring(0, this.streetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenes.address.street.substring(this.streetIdeiglenes.address.street.lastIndexOf(' '), this.streetIdeiglenes.address.street.length + 1).trim();
        if (this.streetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.worksheet.installationAddress.publicPlaceName = street;
          this.worksheet.installationAddress.publicPlaceType = type;
        } else {
          console.log('2b');
        }
      }
      //
      //
    },
    preventEnter() {
      //
    },
    clearAllAddressInputs() {
      this.selectFieldOptions.postalCodes = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.cities = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streets = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearch = '';
      this.postalCodeSearchIsLoading = false;
      this.citySearch = '';
      this.citySearchIsLoading = false;
      this.streetSearch = '';
      this.streetSearchIsLoading = false;
      this.streetIdeiglenes = '';
      this.worksheet.installationAddress.postalCode = '';
      this.worksheet.installationAddress.city = '';
      this.worksheet.installationAddress.publicPlaceType = '';
      this.worksheet.installationAddress.publicPlaceName = '';
      this.worksheet.installationAddress.streetNumber = '';
    },
    saveDeliveryInAddressData() {
      this.deliveryInAddressString = this.worksheet.installationAddress.postalCode + ' '
        + this.worksheet.installationAddress.city + ', '
        + this.worksheet.installationAddress.publicPlaceName + ' '
        + this.worksheet.installationAddress.publicPlaceType + ' '
        + this.worksheet.installationAddress.streetNumber;
      this.deliveryInAddressModal = false;
    },
    /***
     * SZÁMLÁZÁSI CÍM MEGADÁSÁHOZ A FGV-EK
     */
    async getPostalCodesByCountryInvoice(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.worksheet.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodesInvoice = response.data;
        this.postalCodeSearchIsLoadingInvoice = false;
        this.selectFieldOptions.postalCodesInvoice.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCodeInvoice() {
      let postalcode = 0;
      if (this.worksheet.billingAddress.postalCode && this.worksheet.billingAddress.postalCode.address) {
        postalcode = this.worksheet.billingAddress.postalCode.address.postalCode;
        this.worksheet.billingAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.worksheet.billingAddress.postalCode, this.worksheet.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.citiesInvoice = response.data;
        this.citySearchIsLoadingInvoice = false;
        this.selectFieldOptions.citiesInvoice.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCodeInvoice(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.worksheet.billingAddress.postalCode, this.worksheet.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streetsInvoice = response.data;
        this.streetSearchIsLoadingInvoice = false;
        this.selectFieldOptions.streetsInvoice.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelectInvoice() {
      await this.getCityByPostalCodeInvoice();
      if (this.selectFieldOptions.citiesInvoice.length === 2) {
        this.worksheet.billingAddress.city = this.selectFieldOptions.citiesInvoice[1].address.city;
        this.citySearchInvoice = this.selectFieldOptions.citiesInvoice[1].address.city;
      }
      this.worksheet.billingAddress.publicPlaceName = '';
      this.worksheet.billingAddress.publicPlaceType = '';
      this.worksheet.billingAddress.streetNumber = '';
      this.streetIdeiglenesInvoice = '';
      this.streetSearchInvoice = null;
    },
    onPublicPlaceSelectInvoice() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.length > 0) {
        street = this.streetIdeiglenesInvoice.substring(0, this.streetIdeiglenesInvoice.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.substring(this.streetIdeiglenesInvoice.lastIndexOf(' '), this.streetIdeiglenesInvoice.length + 1).trim();
        if (this.streetIdeiglenesInvoice.lastIndexOf(' ') > 0) {
          // console.log('3a');
          this.worksheet.billingAddress.publicPlaceName = street;
          this.worksheet.billingAddress.publicPlaceType = type;
        } else {
          // console.log('3b');
          this.serviceRequestForm.billingAddress.publicPlaceName = this.streetIdeiglenesInvoice;
          type = this.selectFieldOptions.streetsInvoice[1].address.street.substring(this.selectFieldOptions.streetsInvoice[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streetsInvoice[1].address.street.length + 1).trim();
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.address) {
        street = this.streetIdeiglenesInvoice.address.street.substring(0, this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.address.street.substring(this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '), this.streetIdeiglenesInvoice.address.street.length + 1).trim();
        if (this.streetIdeiglenesInvoice.address.street.lastIndexOf(' ') > 0) {
          // console.log('4a');
          this.worksheet.billingAddress.publicPlaceName = street;
          this.worksheet.billingAddress.publicPlaceType = type;
        } else {
          console.log('4b');
        }
      }
    },
    clearAllAddressInputsInvoice() {
      this.selectFieldOptions.postalCodesInvoice = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.citiesInvoice = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streetsInvoice = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearchInvoice = '';
      this.postalCodeSearchIsLoadingInvoice = false;
      this.citySearchInvoice = '';
      this.citySearchIsLoadingInvoice = false;
      this.streetSearchInvoice = '';
      this.streetSearchIsLoadingInvoice = false;
      this.streetIdeiglenesInvoice = '';
      this.worksheet.billingAddress.postalCode = '';
      this.worksheet.billingAddress.city = '';
      this.worksheet.billingAddress.publicPlaceType = '';
      this.worksheet.billingAddress.publicPlaceName = '';
      this.worksheet.billingAddress.streetNumber = '';
    },
    saveBillingAddressData() {
      this.billingAddressString = this.worksheet.billingAddress.postalCode + ' '
        + this.worksheet.billingAddress.city + ', '
        + this.worksheet.billingAddress.publicPlaceName + ' '
        + this.worksheet.billingAddress.publicPlaceType + ' '
        + this.worksheet.billingAddress.streetNumber;
      this.billingAddressModal = false;
    },
    openBillingAddressModal() {
      // a meglévő szállítási címet kimentjük egy ideiglenes változóba,
      // hogy ha a user a Mégsem gombra kattint, akkor megmaradjon az előzőleg beírt cím
      this.billingAddressBackup = this.worksheet.billingAddress;
      this.billingAddressModal = true;
    },
    closeBillingAddressModalWithoutSave() {
      // Mégsem gombra kattintva visszaszerezzük az előzőleg beírt címet
      this.worksheet.billingAddress = this.billingAddressBackup;
      this.billingAddressModal = false;
      this.billingAddressBackup = null;
    },
    purchaseDateIsOK(date) {
      const inputDate = dayjs(date);
      const maxD = dayjs(this.maxDate);
      const max = dayjs(maxD).subtract(1, 'day');
      if (date) {
        if (date.length === 10) {
          if (new Date(date).getDate()) {
            return inputDate.isAfter(dayjs(max));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async downloadFile(document) {
      if (document !== {}) {
        let fileHandler = {
          table: document.table,
          hash: document.hash,
        }
        const respone = await this.$store.dispatch('DownloadLGWorksheetDocument', { doc: fileHandler, name: document.description });
      } else {
        this.responseDialog.response.error = true;
        this.responseDialog.response.message = 'ERROR_MESSAGES.DOWNLOAD_FILE';
        this.responseDialog.visible = true;
      }
    },
  },
  watch: {
    postalCodeSearch(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountry(value);
    },
    streetSearch(value) {
      this.streetSearchIsLoading = true;
      this.getStreetByPostalCode(value);
    },
    postalCodeSearchInvoice(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountryInvoice(value);
    },
    streetSearchInvoice(value) {
      this.streetSearchIsLoading = true;
      this.getStreetByPostalCodeInvoice(value);
    },
    billingAddressIsEqualdeliveryInAddress(value) {
      if (value) {
        this.worksheet.billingAddress.postalCode = this.worksheet.installationAddress.postalCode;
        this.worksheet.billingAddress.city = this.worksheet.installationAddress.city;
        this.worksheet.billingAddress.publicPlaceName = this.worksheet.installationAddress.publicPlaceName;
        this.worksheet.billingAddress.publicPlaceType = this.worksheet.installationAddress.publicPlaceType;
        this.worksheet.billingAddress.streetNumber = this.worksheet.installationAddress.streetNumber;
        this.worksheet.billingAddress.other = this.worksheet.installationAddress.other;
        this.saveBillingAddressData();
      } else {
        this.clearAllAddressInputsInvoice();
        this.worksheet.billingAddress.billingName = '';
        this.worksheet.billingAddress.taxNumber = '';
        this.worksheet.billingAddress.other = '';
        this.billingAddressString = '';
      }
    },
  }
};
</script>

<style scoped>

</style>
